<template>
  <anonymous-page-layout>
    <p class="title font-weight-thin">Veuillez selectionnez votre accès</p>
    <v-btn depressed color="light" class="mr-5" @click="login('patient')">Patient ou aidant</v-btn>
    <v-btn depressed color="secondary" @click="login('professional')">Professionnel de santé</v-btn>
  </anonymous-page-layout>
</template>

<script>
  import AnonymousPageLayout from '@/modules/core/layout/AnonymousPageLayout'

  export default {
    name: 'Welcome',
    components: {AnonymousPageLayout},
    props: {
      target: String
    },
    watch: {
      ['$auth.user']: {
        handler: function (value) {
          if (value) {
            this.$router.push('/')
          }
        },
        immediate: true
      }
    },
    methods:{
      login(loginHint) {
        this.$auth.loginWithRedirect({ appState: { targetUrl: this.target }, loginHint })
      }
    }
  }
</script>
