<template>
  <v-container fluid class="homepage primary fill-height">
    <div class="visual-stuff">
      <div />
      <div />
      <div />
      <div />
    </div>
    <v-row class="homepage-content">
      <v-col cols="12" sm="8" md="6" lg="4" class="mx-auto text-center">
        <img alt="Bienvenue sur MedicalSpace" src="@/assets/img/applogo/mspace-logo-light.png"
             style="max-width:100%" class="mx-auto px-6 px-sm-0"
        >
        <div class="mt-6">
          <slot />
        </div>
      </v-col>
    </v-row>
    <v-footer padless fixed color="transparent" class="white--text">
      <v-col class="text-center" cols="12">
        <img alt="Developpé par E-Synaps" src="@/assets/img/applogo/esyn-logo.png"
             style="max-width:180px" class="mx-auto d-none d-md-block"
        >
        <div class="caption mt-1">
          E-Synaps {{ new Date().getFullYear() }} — Tous droits réservés
        </div>
      </v-col>
    </v-footer>
  </v-container>
</template>

<script>
  export default {
    name: 'AnonymousPageLayout'
  }
</script>


<style scoped lang="scss">
  .homepage {
    color: white;
  }

  .homepage-content {
    z-index: 3;
  }

  .visual-stuff {
    z-index: 1;

    div {
      border-radius: 50%;
      position: fixed;
      background-color: transparent;

      &:nth-child(1) {
        width: 30vw;
        height: 30vw;
        top: -10vw;
        left: -10vw;
        border: 15px solid rgba(255, 255, 255, 0.05);
      }

      &:nth-child(2) {
        width: 30vh;
        height: 30vh;
        bottom: -5vh;
        right: -15vh;
        border: 25px solid rgba(0, 0, 0, 0.2);
      }

      &:nth-child(3) {
        width: 27vw;
        height: 27vw;
        top: -8.4vw;
        left: -8.4vw;
        border: 15px solid #69b099;
        border-left-color: transparent;
        animation: rotate 6s infinite linear;
      }

      &:nth-child(4) {
        width: 21vh;
        height: 21vh;
        bottom: -0.5vh;
        right: -10.7vh;
        border: 10px solid #69b099;
        border-top-color: transparent;
        animation: rotate 8s infinite linear;
      }
    }
  }
</style>
